export default {
  beforeMount(el, binding) {
    const animationClass = `animate__${binding.value}`;
    el.classList.add("animate__animated", "animate__fadeOut");

    const onScroll = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          el.classList.remove("animate__fadeOut");
          el.classList.add(animationClass);

          // If 'once' modifier is used, stop observing after the animation is triggered
          if (binding.modifiers.once) {
            observer.unobserve(el);
          }
        }
      });
    };

    const observer = new IntersectionObserver(onScroll, {
      threshold: 0.1,
    });

    observer.observe(el);
  },
};
