<script lang="js">
import Swal from 'sweetalert2';
export default {
  data() {
    return {
      password: "",
      errorMessage: "",
    };
  },
  methods: {
    handleLogin() {
      const staticPassword = "F1stc1typ0ly23$";

      if (this.password === staticPassword) {
        Swal.fire({
          icon: "success",
          title: "Login successful!",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
        }).then(() => {
          localStorage.setItem("isLoggedIn", true);
          this.$router.push({ name: "adminDashboard" });
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Invalid password. Please try again!",
        });
      }
    },
  },
};
</script>

<template>
  <div class="container-fluid">
    <div class="d-flex align-items-center py-4 bg-body-tertiary vh-100">
      <main class="auth-container w-100 m-auto">
        <img
          src="../assets/logo.png"
          class="mb-3"
          alt=""
          width="96.26"
          height="70"
        />
        <h4 class="fw-bold">Admin Login</h4>
        <form @submit.prevent="handleLogin">
          <div class="form-floating mb-3 mt-5">
            <input
              type="password"
              v-model="password"
              class="form-control"
              id="floatingPassword"
              placeholder="Password"
              required
            />
            <label for="floatingPassword">Enter Password</label>
          </div>
          <p v-if="errorMessage" class="text-danger">{{ errorMessage }}</p>
          <button type="submit" class="btn btn-primary">Login</button>
        </form>
      </main>
    </div>
  </div>
</template>

<style scoped>
.auth-container {
  max-width: 330px;
  padding: 1rem;
}
</style>
