<script lang="js">
// import { RouterLink } from 'vue-router';
import CTA from "@/components/CTA.vue";
import Swal from 'sweetalert2';
import { Tooltip } from 'bootstrap';

export default {
  name: '',
  data() {
    return {
      formData: {
        name: "",
        course: "",
        address: "",
        mobile: "",
        email: "",
        jambRegNo: "",
        jambScore: "",
        applicationNo: "",
      },
    };
  },
  methods: {
    submitForm() {
      // Perform form validation or further processing
      console.log("HTML form:", this.formData);

      fetch("https://firstcitypoly.edu.ng/api/submit_scholarship.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(this.formData),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {

            // Show success alert
            let timerInterval;
            Swal.fire({
              title: "Success!",
              html: "Your scholarship application has been submitted successfully. This will close in <b></b> milliseconds.",
              timer: 2000,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading();
                const timer = Swal.getPopup().querySelector("b");
                timerInterval = setInterval(() => {
                  timer.textContent = `${Swal.getTimerLeft()}`;
                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              }
            }).then((result) => {
              if (result.dismiss === Swal.DismissReason.timer) {
                this.resetForm();
              }
            });

            console.log("Server form:", this.data);
          } else {
            // Handle errors if response is not ok
            Swal.fire({
              title: 'Error!',
              text: data.message || 'Something went wrong. Please try again.',
              icon: 'error',
              confirmButtonText: 'OK',
            });
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          Swal.fire({
            title: 'Error!',
            text: 'Could not submit the form. Please check your network connection.',
            icon: 'error',
            confirmButtonText: 'OK',
          });
        });
    },
    resetForm() {
      this.formData.name = '';
      this.formData.course = '';
      this.formData.address = '';
      this.formData.mobile = '';
      this.formData.email = '';
      this.formData.jambRegNo = '';
      this.formData.jambScore = '';
      this.formData.applicationNo = '';
    },
    checkApplicationId() {
      // Check if the user has entered a valid application ID
      this.hasApplicationId = !!this.formData.applicationNo;
    },
  },
  mounted() {
   // Initialize tooltip with HTML content
   const tooltipTriggerEl = document.getElementById('admissionTooltipBtn');
    new Tooltip(tooltipTriggerEl, {
      html: true, // Enable HTML content inside the tooltip
      title: `No application ID?, <a href="https://onlineapp.firstcitypoly.edu.ng/OnlineApp/Default" target="_blank" class="link-light">click here</a> to complete your application.`
    });
  },
  computed: {},
  components: { CTA },
  created: function () { }
}
</script>

<template>
  <div>
    <div class="bg-image-overlay">
      <div class="overlay"></div>
      <div class="content">
        <h1 v-scrollanimation.once="'fadeInUp'">Scholarship Application</h1>
      </div>
    </div>
  </div>

  <div class="container mt-5 text-start">
    <h4>Instructions</h4>
    <p>
      We are excited to announce the availability of 100 scholarship slots for
      deserving students across all courses. To apply for the scholarship,
      carefully read and follow the instructions below:
    </p>

    <ol id="instructions-list" class="mb-5" type="1">
      <li>
        Eligibility Criteria:
        <ul style="list-style-type: disc">
          <li>
            The scholarship is open to all students applying to First City
            Polytechnic for the 2024/2025 academic session.
          </li>
          <li>
            Only students who have completed their admission application via the
            First City Polytechnic Admission Portal are eligible to apply for
            the scholarship.
          </li>
          <li>
            Applicants must provide their Application Number from the admission
            portal to complete the scholarship application.
          </li>
        </ul>
      </li>
      <li>
        Application Process:
        <ul style="list-style-type: disc">
          <li>
            Fill in all the required information in the Scholarship Application
            Form. Incomplete applications will not be considered.
          </li>
          <li>
            Ensure that you have your Application Number from the Admission
            Portal before starting the scholarship application. If you do not
            have an Application Number, you must first complete your admission
            application by visiting
            <a
              href="https://onlineapp.firstcitypoly.edu.ng/OnlineApp/Default"
              target="_blank"
              rel="noopener noreferrer"
              class="link-dark link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
              >Admission Portal</a
            >.
          </li>
        </ul>
      </li>
      <li>
        Required Information for Scholarship Application:
        <ul style="list-style-type: disc">
          <li>
            Full Name: Enter your full name as it appears in your application.
          </li>
          <li>
            Course: Select your course of study from the available options
            (e.g., ND Business Administration, ND Electrical Engineering, ND
            Computer Science, etc.).
          </li>
          <li>Address: Provide your current residential address.</li>
          <li>
            Mobile Number: Enter a valid mobile phone number for communication
            purposes.
          </li>
          <li>Email: Provide an active email address.</li>
          <li>
            JAMB Registration Number: (If applicable) Enter your JAMB
            registration number.
          </li>
          <li>JAMB Score: (If applicable) Enter your JAMB score.</li>
          <li>
            Application Number: This is mandatory. You must provide the
            application number generated during your admission application.
          </li>
        </ul>
      </li>
      <li>
        Important Notes:
        <ul style="list-style-type: disc">
          <li>
            Only 100 scholarships are available, and submission of the
            scholarship form does not guarantee selection.
          </li>
          <li>
            Scholarships will be awarded based on merit, and applicants are
            expected to meet the necessary requirements for their chosen course
            of study.
          </li>
          <li>
            Failure to provide an Application Number or any required information
            will result in disqualification.
          </li>
          <li>
            The scholarship form is available online, and all applications must
            be submitted via the form.
          </li>
        </ul>
      </li>
      <li>
        Deadline:
        <ul style="list-style-type: none">
          <li>
            Ensure you submit your application before the closing date (to be
            announced).
          </li>
        </ul>
      </li>
      <li>
        Questions?
        <ul style="list-style-type: none">
          <li>
            For inquiries or assistance with your application, please contact
            the Admissions Office at
            <a
              href="mailto:admissions@firstcitypoly.edu.ng"
              class="link-dark link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
              >admissions@firstcitypoly.edu.ng</a
            >.
          </li>
        </ul>
      </li>
    </ol>

    <hr />

    <form @submit.prevent="submitForm" class="text-start m-5">
      <div class="row">
        <!-- Name Field -->
        <div class="col-md-6 mb-3">
          <label for="name" class="form-label">Name</label>
          <input
            type="text"
            class="form-control"
            id="name"
            v-model="formData.name"
            required
          />
        </div>

        <!-- Course Field -->
        <div class="col-md-6 mb-3">
          <label for="course" class="form-label">Course</label>
          <select
            id="course"
            class="form-select"
            v-model="formData.course"
            required
          >
            <option disabled value="">Select a course</option>
            <option>ND Business Administration and Management</option>
            <option>ND Computer Engineering</option>
            <option>ND Computer Science</option>
            <option>ND Electrical Engineering</option>
            <option>ND Statistics</option>
          </select>
        </div>
      </div>

      <div class="row">
        <!-- Address Field -->
        <div class="col-md-12 mb-3">
          <label for="address" class="form-label">Address</label>
          <input
            type="text"
            class="form-control"
            id="address"
            v-model="formData.address"
            required
          />
        </div>
      </div>

      <div class="row">
        <!-- Email Field -->
        <div class="col-md-6 mb-3">
          <label for="email" class="form-label">Email</label>
          <input
            type="email"
            class="form-control"
            id="email"
            v-model="formData.email"
            required
          />
        </div>
        <!-- Mobile No Field -->
        <div class="col-md-6 mb-3">
          <label for="mobile" class="form-label">Mobile Number</label>
          <input
            type="tel"
            class="form-control"
            id="mobile"
            v-model="formData.mobile"
            required
          />
        </div>
      </div>

      <div class="row">
        <!-- Jamb Reg No Field -->
        <div class="col-md-6 mb-3">
          <label for="jambRegNo" class="form-label">
            JAMB Registration Number
          </label>
          <input
            type="text"
            class="form-control"
            id="jambRegNo"
            v-model="formData.jambRegNo"
            required
          />
        </div>

        <!-- Jamb Score Field -->
        <div class="col-md-6 mb-3">
          <label for="jambScore" class="form-label"
            >JAMB Score (optional)</label
          >
          <input
            type="number"
            class="form-control"
            id="jambScore"
            v-model="formData.jambScore"
          />
        </div>
      </div>

      <div class="row">
        <!-- Application Number Field -->
        <div class="col-md-6 mb-3">
          <label for="applicationNumber" class="form-label"
            >Application Number
            <button
              type="button"
              class="btn btn-sm px-2 py-0 rounded-circle btn-outline-dark"
              id="admissionTooltipBtn"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              data-bs-custom-class="custom-tooltip"
            >
              <span> ! </span>
            </button>
          </label>
          <input
            type="text"
            class="form-control"
            id="applicationNumber"
            v-model="formData.applicationNo"
            required
          />
        </div>

        <!-- Submit Button -->
        <div class="col-md-6 mb-3 align-self-end">
          <button type="submit" class="btn btn-primary w-100 float-end">
            Submit Application
          </button>
        </div>
      </div>
    </form>
  </div>

  <div class="container-fluid bg-light py-3">
    <CTA
      heading="Join First City Polytechnic Today!"
      message="Start your academic journey with us. Apply now for the 2024/2025 academic session and unlock new career opportunities!"
      link="https://onlineapp.firstcitypoly.edu.ng/OnlineApp/Default"
      button="Apply for Admission Now"
      v-scrollanimation.once="'zoomIn'"
    />
  </div>
</template>

<style scoped>
.bg-image-overlay {
  position: relative;
  background-image: url("@/assets/img/scholarship.png");
  background-size: cover;
  background-position: center;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0e1438dc;
  z-index: 1;
}

.content {
  position: relative;
  z-index: 2;
}

#instructions-list > li {
  font-weight: bold;
}

#instructions-list ul li {
  font-weight: normal;
}
</style>
