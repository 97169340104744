<script lang="js">
import CTA from "@/components/CTA.vue";

export default {
  components: {
    CTA,
  },
};
</script>

<template>
  <div class="contact">
    <div class="bg-image-overlay">
      <div class="overlay"></div>
      <div class="content">
        <h1 v-scrollanimation.once="'fadeInUp'">Contact Us</h1>
      </div>
    </div>

    <div class="container mb-5">
      <div class="w-100 p-5 text-dark">
        <h1 v-scrollanimation.once="'fadeInUp'">Get in touch</h1>
        <div class="yellow-dash" v-scrollanimation.once="'fadeInUp'"></div>
        <p v-scrollanimation.once="'fadeIn'">
          Want to get in touch? We'd love to hear from you. Here's how you can
          reach us...
        </p>
      </div>
      <div class="row">
        <div class="col-sm-4 mb-3 mb-sm-0">
          <div
            v-scrollanimation.once="'fadeInUp'"
            class="card h-100 rounded-0 border-0 shadow-lg"
          >
            <div class="card-body">
              <h5 class="card-title fw-bold">Address</h5>
              <p class="card-text">
                18, Quarry Road, Ibara, Abeokuta Ogun State, Nigeria.
              </p>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3958.913725725378!2d3.326501975033223!3d7.135975115742937!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103a4c701ed3dfbb%3A0x850dcae57deb4c5b!2s18%20Quarry%20Rd%2C%20Surulere%2C%20Abeokuta%20111102%2C%20Ogun%20State%2C%20Nigeria!5e0!3m2!1sen!2suk!4v1724118680495!5m2!1sen!2suk"
                width="100%"
                height="300"
                style="border: 0"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
        <div class="col-sm-4 mb-3 mb-sm-0">
          <div
            v-scrollanimation.once="'fadeInUp'"
            class="card h-100 rounded-0 border-0 shadow-lg"
          >
            <div class="card-body">
              <h5 class="card-title fw-bold">Talk to us</h5>
              <div class="card-text text-start mt-5">
                <p>
                  Phone Number:
                  <a
                    class="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover"
                    href="tel:+2347063377322"
                  >
                    +234 (0) 706 337 7322
                  </a>
                </p>
                <p>Email Addresses:</p>
                <ul>
                  <li>
                    General Inquiries:
                    <a
                      href="mailto:info@firstcitypolytechnic.edu.ng"
                      class="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover"
                    >
                      info@firstcitypolytechnic.edu.ng
                    </a>
                  </li>
                  <li>
                    Admissions:
                    <a
                      href="mailto:admission@firstcitypolytechnic.edu.ng"
                      class="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover"
                    >
                      admission@firstcitypolytechnic.edu.ng
                    </a>
                  </li>
                  <li>
                    Careers:
                    <a
                      href="mailto:careers@firstcitypolytechnic.edu.ng"
                      class="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover"
                    >
                      careers@firstcitypolytechnic.edu.ng
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-4 mb-3 mb-sm-0">
          <div
            v-scrollanimation.once="'fadeInUp'"
            class="card h-100 rounded-0 border-0 shadow-lg"
          >
            <div class="card-body">
              <h5 class="card-title fw-bold">Social Media</h5>

              <p class="card-text mt-5">
                Stay connected with us through our social media channels to keep
                up with the latest news, events, and updates from First City
                Polytechnic.
              </p>

              <div class="d-flex justify-content-around my-5">
                <a
                  href="https://www.facebook.com/firstcitypoly"
                  target="_blank"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    fill="currentColor"
                    class="bi bi-facebook"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951"
                    />
                  </svg>
                </a>
                <a href="https://x.com/Firstcitypoly" target="_blank">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    fill="currentColor"
                    class="bi bi-twitter"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334q.002-.211-.006-.422A6.7 6.7 0 0 0 16 3.542a6.7 6.7 0 0 1-1.889.518 3.3 3.3 0 0 0 1.447-1.817 6.5 6.5 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.32 9.32 0 0 1-6.767-3.429 3.29 3.29 0 0 0 1.018 4.382A3.3 3.3 0 0 1 .64 6.575v.045a3.29 3.29 0 0 0 2.632 3.218 3.2 3.2 0 0 1-.865.115 3 3 0 0 1-.614-.057 3.28 3.28 0 0 0 3.067 2.277A6.6 6.6 0 0 1 .78 13.58a6 6 0 0 1-.78-.045A9.34 9.34 0 0 0 5.026 15"
                    />
                  </svg>
                </a>
                <a href="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    fill="currentColor"
                    class="bi bi-instagram"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <CTA
      heading="Join First City Polytechnic Today!"
      message="Start your academic journey with us. Apply now for the 2024/2025 academic session and unlock new career opportunities!"
      link="https://onlineapp.firstcitypoly.edu.ng/OnlineApp/Default"
      button="Apply for Admission Now"
      v-scrollanimation.once="'zoomIn'"
    />
  </div>
</template>

<style scoped>
.bg-image-overlay {
  position: relative;
  background-image: url("@/assets/img/contact.jpg");
  background-size: cover;
  background-position: center;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0e1438dc;
  z-index: 1;
}

.content {
  position: relative;
  z-index: 2;
}
</style>
