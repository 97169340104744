<script lang="js">
export default {
  props: {
    heading: {
        type: String,},
    message:  {
        type: String,},
    link:  {
        type: String,
        default: "#"},
    button: {
        type: String,
    }
  },
  computed: {
    hrefLink() {
      return this.link;
    },
  },
};
</script>

<template>
  <div class="container my-5">
    <div class="p-5 text-center fcp-bg-primary rounded-3 text-white">
      <h1 class="text-white">{{ heading }}</h1>
      <div class="yellow-dash"></div>
      <p class="lead px-md-5 text-break">
        {{ message }}
      </p>
      <a
        :href="hrefLink"
        role="button"
        class="btn btn-outline-warning rounded-pill px-3 py-2"
        v-scrollanimation.once="'fadeInUp'"
      >
        {{ button }}
      </a>
    </div>
  </div>
</template>

<style scoped></style>
