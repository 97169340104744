<script lang="js">
import CTA from "@/components/CTA.vue";

export default {
 data () {
    return {
      teamMembers: [
        {
          name: "Dr IK Oyeyinka",
          role: "Staff",
          image: require('@/assets/img/iko.jpg'),
          description: "Dr. I.K. Oyeyinka holds a Ph.D. in Computer Science from the Federal University of Agriculture, Abeokuta. With a distinguished career, he served as the former Rector of Gateway ICT Polytechnic, Saapade. Currently, he is a Senior Lecturer at Yaba College of Technology, Yaba, Lagos, where he continues to contribute to the academic and professional growth of students."
        },
        {
          name: "Dr (Mrs) Felicia Oyeyinka",
          role: "Staff",
          image: require('@/assets/img/foo.jpeg'),
          description: "Dr. (Mrs.) F.I. Oyeyinka earned her Ph.D. in Computer Science from Babcock University, Ilishan-Remo, Nigeria. She is a seasoned Senior Data Analyst with over 24 years of experience at Yaba College of Technology, Yaba. Her expertise in data analysis has been instrumental in advancing the institution's research and academic endeavors."
        },
        {
          name: "Mr Clement Godwin",
          role: "Staff",
          image: require('@/assets/img/clg.jpg'),
          description: "Mr. Clement Godwin holds a Master’s degree in Business Administration. His extensive knowledge in business management has been pivotal in steering organizational strategies and ensuring operational excellence within the team."
        },
        {
          name: "Mr Temitope Oyeyinka",
          role: "IT",
          image: require('@/assets/img/tpo.jpg'),
          description: "Mr. Temitope Oyeyinka obtained his M.Sc. in Advacned Computer Science from the University of Sussex, UK. With over 5 years of experience in software development, he has developed numerous mobile and web applications, contributing significantly to the field of technology."
        },
        {
          name: "Mr Daniel Oyeyinka",
          role: "Staff",
          image: require('@/assets/img/doo.jpg'),
          description: "Mr. Daniel Oyeyinka holds a B.Sc. in Electrical Engineering from Bowen University. His technical expertise and innovative approach to engineering projects have been crucial in developing and maintaining robust electrical systems."
        },
        {
          name: "Mr Olaiya",
          role: "Staff",
          image: "https://placehold.co/400",
          description: "Mr. Olaiya has an M.Sc. in Computer Engineering. His deep understanding of computer systems and engineering principles allows him to design and implement sophisticated technological solutions."
        },
        {
          name: "Mr Christain Ayetobi",
          role: "Staff",
          image: "https://placehold.co/400",
          description: "Mr. Christian Ayetobi earned his B.Sc. in Public Administration. His administrative acumen and leadership skills have been vital in managing the team’s operations and ensuring smooth organizational workflow."
        },
        {
          name: "Miss Bolaji Oyeyinka",
          role: "Staff",
          image: "https://placehold.co/400",
          description: "Miss Bolaji Oyeyinka holds a B.Sc. in Nursing from Babcock University, Ogun. Her dedication to healthcare and nursing has been evident through her commitment to patient care and her ability to manage complex medical situations with professionalism and compassion."
        },
      ]
    };
  },
  components: { CTA
  },
};
</script>

<template>
  <div class="about">
    <div class="bg-image-overlay">
      <div class="overlay"></div>
      <div class="content">
        <h1 v-scrollanimation.once="'fadeInUp'">About Us</h1>
      </div>
    </div>

    <div class="container-fluid p-5 vision-mission bg-light">
      <div class="row p-md-5 mx-md-5">
        <div class="col-md-6 mt-5" v-scrollanimation.once="'fadeInUp'">
          <h5>
            "To produce individuals with technological expertise capable of
            using their knowledge for national development”
          </h5>
          <h4 class="text-start d-grid">
            <span class="yellow-dash"></span>Vision
          </h4>
        </div>
        <div class="col-md-6 mt-5" v-scrollanimation.once="'fadeInUp'">
          <h5>
            “To be the top private tertiary educational institution in the area
            of technology and innovation with the aim of producing highly
            skilled individuals that will change the technological landscape of
            Nigeria and Africa at large”
          </h5>

          <h4 class="text-start d-grid">
            <span class="yellow-dash"></span>Mission
          </h4>
        </div>
      </div>
    </div>

    <div class="container p-3 my-5">
      <div class="row d-flex justify-content-center align-items-center">
        <div
          class="col-md-4 text-start pe-md-5"
          v-scrollanimation.once="'fadeInUp'"
        >
          <h3 class="fw-bold">What makes us different?</h3>
          <p>
            Our campus is equipped with modern laboratories, workshops, and
            learning spaces that replicate real industry environments. This
            ensures that you gain practical skills in settings that mirror the
            professional world.
          </p>
        </div>
        <div class="col-md-7 ps-md-5" v-scrollanimation.once="'fadeIn'">
          <div class="container">
            <div class="row d-flex align-items-center">
              <div
                class="col-md-5 text-end py-4 pe-4"
                style="background-color: #d5eaf5"
              >
                <span class="display-4">5</span>
              </div>
              <div class="col-md-5 text-start py-4">
                <p class="mb-0">Fully Accredited Courses</p>
              </div>
            </div>
            <div class="row d-flex align-items-center">
              <div
                class="col-md-5 text-end py-4 pe-4"
                style="background-color: #e3dfee"
              >
                <span class="display-4">14.6%</span>
              </div>
              <div class="col-md-5 text-start py-4">
                <p class="mb-0">
                  International Students with 8% under Cultural Council.
                </p>
              </div>
            </div>
            <div class="row d-flex align-items-center">
              <div class="col-md-5 text-end py-4 pe-4">
                <span class="display-4">180</span>
              </div>
              <div
                class="col-md-5 text-start py-4"
                style="background-color: #e7dfd7"
              >
                <p class="mb-0">
                  Teaching faculty with highly qualified men and women.
                </p>
              </div>
            </div>
            <div class="row d-flex align-items-center">
              <div
                class="col-md-5 text-end py-4 pe-4"
                style="background-color: #e0eddf"
              >
                <span class="display-4">37%</span>
              </div>
              <div class="col-md-5 text-start py-4">
                <p class="mb-0">
                  Students receive financial aid and selected via entrance test.
                </p>
              </div>
            </div>
            <div class="row d-flex align-items-center">
              <div class="col-md-5 text-end py-4 pe-4">
                <span class="display-4 text-danger">10:1</span>
              </div>
              <div
                class="col-md-5 text-start py-4"
                style="background-color: #f6e9d8"
              >
                <p class="mb-0">
                  Students teacher ratio, for proper attention on child.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="team py-5 bg-light">
      <div class="container">
        <h1 class="fw-bold" v-scrollanimation.once="'fadeInUp'">
          Meet the team
        </h1>
        <div class="yellow-dash" v-scrollanimation.once="'fadeInUp'"></div>
        <div class="row d-flex justify-content-center mt-5 g-4">
          <div
            v-for="(member, index) in teamMembers"
            :key="index"
            class="col-md-6 col-lg-4"
          >
            <div
              class="card h-100 rounded-0 border-0 shadow-lg"
              v-scrollanimation.once="'fadeInUp'"
            >
              <img
                :src="member.image"
                height="310"
                class="object-fit-cover"
                :alt="`Image of ${member.name}`"
              />
              <div class="card-body text-start">
                <h5 class="card-title fw-bold">{{ member.name }}</h5>
                <h6 class="text-secondary">{{ member.role }}</h6>
                <p class="card-text">{{ member.description }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <CTA
      heading="Interested in joining us?"
      message="We would love to hear from you! Please send your resume and a brief
        cover letter to careers@firstcitypolytechnic.edu.ng. Let's work together to shape the future of education."
      link="mailto:careers@firstcitypolytechnic.edu.ng"
      button="Join today"
      v-scrollanimation.once="'zoomIn'"
    />
  </div>
</template>

<style scoped>
.bg-image-overlay {
  position: relative;
  background-image: url("@/assets/img/bg2.png");
  background-size: cover;
  background-position: center;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0e1438dc;
  z-index: 1;
}

.content {
  position: relative;
  z-index: 2;
}

.vision-mission {
  font-family: "Times New Roman", Times, serif;
}

.team img {
  widows: 100%;
  object-fit: contain;
}

@media (min-width: 991.98px) {
  .team .row {
    margin-left: 10%;
    margin-right: 10%;
  }
}
</style>
