import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import AboutView from "../views/AboutView.vue";
import ContactView from "../views/ContactView.vue";
import ScholarshipView from "../views/ScholarshipView.vue";
import AdminLoginView from "../views/AdminLoginView.vue";
import AdminDashboard from "../views/AdminView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: AboutView,
    // () =>
    //   import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    component: ContactView,
  },
  {
    path: "/funding",
    name: "funding",
    component: ScholarshipView,
  },
  {
    path: "/admin",
    name: "adminLogin",
    component: AdminLoginView,
    meta: { hideHeader: true, hideFooter: true },
  },
  {
    path: "/admin-dashboard",
    name: "adminDashboard",
    component: AdminDashboard,
    meta: { hideHeader: true, hideFooter: true, requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Ensure the navigation guard checks for auth
router.beforeEach((to, from, next) => {
  const isLoggedIn = localStorage.getItem("isLoggedIn");

  // Check if the route requires authentication
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // If not logged in, redirect to the login page
    if (!isLoggedIn) {
      next({ name: "adminLogin" });
    } else {
      next(); // Allow to proceed if logged in
    }
  } else {
    // If trying to access the admin login page while already logged in, redirect to dashboard
    if (to.name === "adminLogin" && isLoggedIn) {
      next({ name: "adminDashboard" });
    } else {
      next(); // No auth required, proceed
    }
  }
});

export default router;
