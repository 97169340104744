<script lang="js">
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { saveAs } from 'file-saver';

export default {
    name: 'Scholarships',
  data() {
    return {
      scholarships: [],
      headings: {
        // display: ['Name', 'Scholarship ID', 'Application ID', 'Date of Application', 'Course', 'Address', 'Mobile', 'Email', 'Jamb Reg No.', 'Jamb Score', 'Status'], // Displayed in the table
        // excel: ['Name', 'Scholarship ID', 'Application ID', 'Date of Application', 'Course', 'Address', 'Mobile', 'Email', 'Jamb Reg No.', 'Jamb Score', 'Status'], // For Excel
        // csv: ['Name', 'Scholarship ID', 'Application ID', 'Date of Application', 'Course', 'Address', 'Mobile', 'Email', 'Jamb Reg No.', 'Jamb Score', 'Status'], // For CSV
        pdf: ['Name', 'Scholarship ID', 'Application ID', 'Date of Application', 'Course', 'Address', 'Mobile', 'Email', 'Jamb Reg No.', 'Jamb Score', 'Status'] // For PDF
      },
    selectedScholarship: null,
    searchQuery: '',
      currentPage: 1,
      perPage: 10,
    };
  },
  mounted() {
    this.fetchScholarships();
    const modalElement = document.getElementById('scholarshipModal');
    modalElement.addEventListener('hide.bs.modal', this.clearSelectedScholarship);
  },
  methods: {
    fetchScholarships() {
      fetch("https://firstcitypoly.edu.ng/api/get_scholarships.php")
        .then((response) => response.json())
        .then((data) => {
          this.scholarships = data;
        })
        .catch((error) => {
          console.error("Error fetching scholarships:", error);
        });
    },
    rejectScholarship(id) {
      if (id === null || id === undefined || id === "") {
        Swal.fire({
          icon: "error",
          title: "Invalid Scholarship",
          text: "Unable to accept the scholarship because the ID is missing or invalid.",
        });
        return;
      }

      Swal.fire({
        title: "Are you sure?",
        text: "Do you want to reject this scholarship?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#888",
        confirmButtonText: "Reject it"
      }).then((result) => {
        if (result.isConfirmed) {
          this.updateScholarshipStatus(id, "rejected");
        }
      });
    },
    acceptScholarship(id) {
      if (id === null || id === undefined || id === "") {
        Swal.fire({
          icon: "error",
          title: "Invalid Scholarship",
          text: "Unable to accept the scholarship because the ID is missing or invalid.",
        });
        return;
      }

      Swal.fire({
        title: "Are you sure?",
        text: "Do you want to accept this scholarship?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#198754",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, accept it!"
      }).then((result) => {
        if (result.isConfirmed) {
          this.updateScholarshipStatus(id, "accepted");
        }
      });
    },
    updateScholarshipStatus(id, status) {
      fetch(`https://firstcitypoly.edu.ng/api/update_scholarship.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id, status }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            Swal.fire({
              icon: "success",
              title: `Scholarship ${status}`,
              text: `This scholarship has been successfully ${status}.`,
              showConfirmButton: false,
              timer: 1500,
            });
            this.fetchScholarships();
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: `Failed to ${status.slice(0, -2)} this scholarship. Please try again.`,
            });
          }
        })
        .catch((error) => {
          console.error(`Error  ${status.slice(0, -2) + `ing`} scholarship:`, error);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `Something went wrong while ${status.slice(0, -2) + `ing`}  this scholarship.`,
          });
        });
    },
    previousPage() {
      if (this.currentPage > 1) this.currentPage--;
    },
    nextPage() {
      if (this.currentPage < this.totalPages) this.currentPage++;
    },
    goToPage(page) {
      this.currentPage = page;
    },
    viewScholarship(scholarship) {
    this.selectedScholarship = scholarship;
  },
  clearSelectedScholarship() {
      this.selectedScholarship = null;
    },
    getFormattedDataForExport() {
      return this.scholarships.map(scholarship => ({
        'Name': scholarship.name,
        'Scholarship ID': scholarship.scholarship_id,
        'Application ID': scholarship.application_no,
        'Date of Application': scholarship.created_at,
        'Course': scholarship.course,
        'Address': scholarship.address,
        'Mobile': scholarship.mobile,
        'Email': scholarship.email,
        'Jamb Reg No.': scholarship.jamb_reg_no,
        'Jamb Score': scholarship.jamb_score,
        'Status': scholarship.status
      }));
    },
    exportToExcel() {
      const formattedData = this.getFormattedDataForExport();
      const worksheet = XLSX.utils.json_to_sheet(formattedData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Scholarships");

      const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
      const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
      saveAs(blob, "Scholarships.xlsx");
    },

    exportToCSV() {
      const formattedData = this.getFormattedDataForExport();
      const worksheet = XLSX.utils.json_to_sheet(formattedData);
      const csvOutput = XLSX.utils.sheet_to_csv(worksheet);
      const blob = new Blob([csvOutput], { type: "text/csv;charset=utf-8;" });
      saveAs(blob, "Scholarships.csv");
    },

    exportToPDF() {
      const doc = new jsPDF();
      doc.text("Scholarship Applications", 10, 10);
      doc.autoTable({
        head: [this.headings.pdf], // Use the mapped PDF headings here
        body: this.scholarships.map(scholarship => [
          scholarship.name,
          scholarship.scholarship_id,
          scholarship.application_no,
          scholarship.created_at,
          scholarship.course,
          scholarship.address,
          scholarship.mobile,
          scholarship.email,
          scholarship.jamb_reg_no,
          scholarship.jamb_score,
          scholarship.status
        ]), // Adjust based on your actual scholarship object properties
      });
      doc.save("Scholarships.pdf");
    },

    printTable() {
      const printContent = document.querySelector("#scholarshipTable").outerHTML;
      const printWindow = window.open("", "_blank");
      printWindow.document.write(`<html><head><title>Print Table</title></head><body>${printContent}</body></html>`);
      printWindow.document.close();
      printWindow.print();
    }
  },
  computed: {
    filteredScholarships() {
      return this.scholarships.filter(scholarship =>
        scholarship.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
    totalPages() {
      return Math.ceil(this.filteredScholarships.length / this.perPage);
    },
    paginatedData() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      return this.filteredScholarships.slice(start, end);
    },
    paginationPages() {
      let pages = [];
      const totalPages = this.totalPages;

      if (totalPages <= 3) {
        // If total pages are less than or equal to 3, show all pages
        pages = Array.from({ length: totalPages }, (_, i) => i + 1);
      } else if (this.currentPage <= 3) {
        // If the current page is less than or equal to 3, show 1, 2, 3, and last page
        pages = [1, 2, 3, '...', totalPages];
      } else if (this.currentPage > 3 && this.currentPage < totalPages - 1) {
        // If current page is greater than 3 and less than the last page, show 1, ..., current page, ..., last page
        pages = [1, '...', this.currentPage, '...', totalPages];
      } else if (this.currentPage >= totalPages - 1) {
        // If current page is on the second-to-last or last page, show last few pages
        pages = [1, '...', totalPages - 2, totalPages - 1, totalPages];
      }

      return pages;
    },
    startItem() {
      if (this.filteredScholarships.length === 0) return 0;
      return (this.currentPage - 1) * this.perPage + 1;
    },
    endItem() {
      if (this.filteredScholarships.length === 0) return 0;
    let end = this.currentPage * this.perPage;
    return end > this.filteredScholarships.length
      ? this.filteredScholarships.length
      : end;
    },
  },
  components: { },
};
</script>

<template>
  <div class="col-lg-10 col-md-12">
    <div class="p-4 text-start">
      <h4 class="fw-bold">Scholarship Applications</h4>
      <p>
        Manage scholarship applications efficiently by reviewing, accepting, or
        rejecting submissions directly from the table below.
      </p>
      <div class="table-responsive small">
        <div class="row">
          <div class="col-5 d-flex align-items-center">
            <div class="dropdown">
              <button
                class="btn btn-dark dropdown-toggle"
                type="button"
                id="exportDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-download"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"
                  />
                  <path
                    d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"
                  />
                </svg>
              </button>
              <ul class="dropdown-menu" aria-labelledby="exportDropdown">
                <li>
                  <a class="dropdown-item" @click="exportToExcel"
                    >Export as Excel</a
                  >
                </li>
                <li>
                  <a class="dropdown-item" @click="exportToCSV"
                    >Export as CSV</a
                  >
                </li>
                <li>
                  <a class="dropdown-item" @click="exportToPDF"
                    >Export as PDF</a
                  >
                </li>
                <!-- <li><a class="dropdown-item" @click="printTable">Print</a></li> -->
              </ul>
            </div>
          </div>
          <div class="col-7">
            <!-- Search Bar -->
            <div class="input-group search-bar p-3">
              <span
                class="input-group-text bg-transparent text-muted"
                id="search-addon"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-search"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"
                  />
                </svg>
              </span>
              <input
                v-model="searchQuery"
                class="form-control border-start-0"
                type="text"
                placeholder="Search by applicants name"
                aria-label="search"
                aria-describedby="search-addon"
              />
            </div>
          </div>
        </div>

        <table
          ref="scholarshipTable"
          class="table table-hover table-sm text-center"
        >
          <caption>
            Showing
            {{
              startItem
            }}
            to
            {{
              endItem
            }}
            of
            {{
              filteredScholarships.length
            }}
            entries
          </caption>
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Course</th>
              <th scope="col">JAMB Score</th>
              <th scope="col">Application No.</th>
              <th scope="col">Submitted on</th>
              <th scope="col">Status</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="scholarship in paginatedData" :key="scholarship.id">
              <td>{{ scholarship.name }}</td>
              <td>{{ scholarship.course }}</td>
              <td>{{ scholarship.jamb_score }}</td>
              <td>{{ scholarship.application_no }}</td>
              <td>{{ scholarship.created_at }}</td>
              <td>{{ scholarship.status }}</td>

              <td>
                <button
                  class="btn btn-sm btn-dark"
                  data-bs-toggle="modal"
                  data-bs-target="#scholarshipModal"
                  @click="viewScholarship(scholarship)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-eye"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z"
                    />
                    <path
                      d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0"
                    />
                  </svg>
                </button>
                <button
                  class="btn btn-sm btn-success mx-2"
                  @click="acceptScholarship(scholarship.id)"
                  :disabled="scholarship.status !== 'pending'"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-check"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z"
                    />
                  </svg>
                </button>
                <button
                  class="btn btn-sm btn-danger"
                  @click="rejectScholarship(scholarship.id)"
                  :disabled="scholarship.status !== 'pending'"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-x"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"
                    />
                  </svg>
                </button>
              </td>
            </tr>
            <tr v-if="paginatedData.length === 0">
              <td colspan="8" class="text-center py-5">
                No scholarships application found
              </td>
            </tr>
          </tbody>
        </table>

        <nav aria-label="Scholarship table navigation">
          <ul class="pagination justify-content-center">
            <!-- Previous Button -->
            <li :class="{ disabled: currentPage === 1 }" class="page-item">
              <a class="page-link" href="#" @click.prevent="previousPage">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-chevron-left"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"
                  />
                </svg>
                Previous</a
              >
            </li>

            <!-- Page Numbers Dynamically Rendered -->
            <li
              v-for="page in paginationPages"
              :key="page"
              :class="{ active: currentPage === page }"
              class="page-item"
            >
              <a class="page-link" href="#" @click.prevent="goToPage(page)">{{
                page
              }}</a>
            </li>

            <!-- Next Button -->
            <li
              :class="{
                disabled:
                  currentPage === totalPages ||
                  filteredScholarships.length === 0,
              }"
              class="page-item"
            >
              <a class="page-link" href="#" @click.prevent="nextPage">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-chevron-right"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
                  />
                </svg>
                Next</a
              >
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>

  <!-- Scholarship details modal -->
  <div
    class="modal fade"
    id="scholarshipModal"
    tabindex="-1"
    aria-labelledby="scholarshipModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="scholarshipModalLabel">
            <strong>Scholarship Details</strong>
          </h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p><strong>Date:</strong> {{ selectedScholarship?.created_at }}</p>
          <p><strong>Name:</strong> {{ selectedScholarship?.name }}</p>
          <p><strong>Course:</strong> {{ selectedScholarship?.course }}</p>
          <p>
            <strong>House address:</strong> {{ selectedScholarship?.address }}
          </p>
          <p>
            <strong>Phone number:</strong> {{ selectedScholarship?.mobile }}
          </p>
          <p><strong>Email:</strong> {{ selectedScholarship?.email }}</p>
          <p>
            <strong>Jamb Registration Number:</strong>
            {{ selectedScholarship?.jamb_reg_no }}
          </p>
          <p>
            <strong>Jamb Score:</strong> {{ selectedScholarship?.jamb_score }}
          </p>
          <p>
            <strong>Application Number:</strong>
            {{ selectedScholarship?.application_no }}
          </p>
          <p><strong>Status:</strong> {{ selectedScholarship?.status }}</p>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-sm btn-success mx-2"
            @click="acceptScholarship(selectedScholarship?.id)"
            :disabled="selectedScholarship?.status !== 'pending'"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-check"
              viewBox="0 0 16 16"
            >
              <path
                d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z"
              />
            </svg>
            Accept
          </button>
          <button
            class="btn btn-sm btn-danger ms-2"
            @click="rejectScholarship(selectedScholarship?.id)"
            :disabled="selectedScholarship?.status !== 'pending'"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-x"
              viewBox="0 0 16 16"
            >
              <path
                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"
              />
            </svg>
            Reject
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
