<script lang="js">
import { RouterView } from 'vue-router';
import BaseNavbar from '@/components/base/BaseNavbar.vue';
import BaseFooter from '@/components/base/Footer.vue';

export default {
  name: 'App',
  computed: {
    hideHeader() {
      return this.$route.meta.hideHeader || false;
    },
    hideFooter() {
      return this.$route.meta.hideFooter || false;
    }
  },
  components: {RouterView, BaseNavbar, BaseFooter}
}
</script>

<template>
  <BaseNavbar v-if="!hideHeader" />
  <RouterView />
  <BaseFooter v-if="!hideFooter" />
</template>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

img {
  pointer-events: none; /* Disables right-click and drag events */
  user-select: none; /* Disables text selection */
}

img::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: transparent;
}

img {
  -webkit-user-drag: none; /* Disable drag on webkit browsers */
  -webkit-touch-callout: none; /* Disable long-press to show context menu on iOS */
  -moz-user-select: none; /* Disable text selection on Firefox */
  -ms-user-select: none; /* Disable text selection on Internet Explorer */
}
</style>
