<script lang="js">

export default {
    name: "Dashboard",
  data() {
    return {};
  },
  mounted() {
  },
  methods: {
  },
  components: { },
};
</script>

<template>
  <div class="col-lg-10 col-md-12">
    <div class="p-4 text-start">
      <h4 class="fw-bold">Dashboard</h4>
    </div>
  </div>
</template>

<style scoped></style>
