<script lang="js"></script>
<template>
  <div
    id="carouselExample"
    class="carousel slide"
    data-bs-ride="carousel"
    data-bs-touch="false"
  >
    <div class="carousel-inner">
      <!-- Carousel Item 1 -->
      <div class="carousel-item active">
        <div class="overlay black-transparent"></div>
        <img
          src="@/assets/img/bg1.png"
          class="d-block w-100 object-fit-cover"
          alt="Image 1"
        />
        <div
          class="carousel-caption d-flex flex-column align-items-center justify-content-center"
        >
          <div class="text-white text-center position-relative text-box">
            <h1 class="fw-bold" v-scrollanimation.once="'fadeInDown'">
              Welcome to First City Polytechnic
            </h1>
            <p class="mt-4" v-scrollanimation.once="'fadeInUp'">
              Your Journey Starts Here:
            </p>
            <a
              href="https://onlineapp.firstcitypoly.edu.ng/OnlineApp/Default"
              target="_blank"
              role="button"
              class="btn btn-outline-primary rounded-pill px-3 py-2"
              v-scrollanimation.once="'fadeInUp'"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-person-fill me-2"
                viewBox="0 0 16 16"
              >
                <path
                  d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6"
                />
              </svg>
              2024/2025 Admission
            </a>
          </div>
        </div>
      </div>

      <!-- Carousel Item 2 -->
      <div class="carousel-item">
        <div class="overlay black-transparent"></div>
        <img
          src="@/assets/img/bg2.png"
          class="d-block w-100 object-fit-cover"
          alt="Image 2"
        />
        <div
          class="carousel-caption d-flex flex-column align-items-center justify-content-center"
        >
          <div class="text-white text-center position-relative text-box">
            <h1 class="fw-bold" v-scrollanimation.once="'fadeInDown'">
              Explore Our Academic Programs
            </h1>
            <p class="mt-4" v-scrollanimation.once="'fadeInUp'">
              Choose from a wide range of diploma and certificate programs
              designed to prepare you for the future.
            </p>
            <a
              href="#"
              role="button"
              class="btn btn-outline-primary rounded-pill px-3 py-2"
              v-scrollanimation.once="'fadeInUp'"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-search me-2"
                viewBox="0 0 16 16"
              >
                <path
                  d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"
                />
              </svg>
              View Programs
            </a>
          </div>
        </div>
      </div>

      <!-- Carousel Item 3 -->
      <div class="carousel-item">
        <div class="overlay black-transparent"></div>
        <img
          src="@/assets/img/signboard.png"
          class="d-block w-100 object-fit-cover"
          alt="Image 3"
        />
        <div
          class="carousel-caption d-flex flex-column align-items-center justify-content-center"
        >
          <div class="text-white text-center position-relative text-box">
            <h1 class="fw-bold" v-scrollanimation.once="'fadeInDown'">
              Experience a Vibrant Campus Life
            </h1>
            <p class="mt-4" v-scrollanimation.once="'fadeInUp'">
              Join a community where learning meets social and cultural
              activities.
            </p>
            <a
              href="#"
              role="button"
              class="btn btn-outline-primary rounded-pill px-3 py-2"
              v-scrollanimation.once="'fadeInUp'"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-exclamation-circle me-2"
                viewBox="0 0 16 16"
              >
                <path
                  d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"
                />
                <path
                  d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z"
                />
              </svg>
              Learn More
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
p {
  font-family: "Muli", Sans-serif;
  font-weight: 300;
}
img {
  width: 100% !important;
  height: 600px !important;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.carousel-caption {
  z-index: 2;
  position: absolute;
  bottom: 0;
  top: 0;
}

.text-box {
  z-index: 3;
  position: relative;
}

.black-transparent {
  background-color: #000000a2;
}

/* Align text to the left on larger screens */
@media (min-width: 992px) {
  .text-box {
    margin-left: 0;
    text-align: left;
  }
}

/* Center text on smaller screens */
@media (max-width: 991.98px) {
  .text-box {
    margin: 0 auto;
    text-align: center;
  }
}
</style>
